import React, { useState } from "react";
import {
  Table,
  Card,
  Tooltip,
  Tag,
  Button,
  Radio,
  Space,
  Spin,
  Alert,
  Switch,
} from "antd";
import { ClearOutlined, EyeFilled, ScheduleOutlined } from "@ant-design/icons";
import { GlobalContextType } from "../../../@types/global";
import { GlobalContext } from "../../../context/globalContext";
import "./styles.css";
import moment from "moment";
import Task from "../Task/Task";
import CriarTarefa from "../CriarTarefa/CriarTarefa";

const TaskBlocks = () => {
  const { moduloTask, currentCliente, setCurrentCliente, clientes, usuarios } =
    React.useContext(GlobalContext) as GlobalContextType;
  const [viewMode, setViewMode] = useState("table");
  const [loading, setLoading] = useState(true);
  const [comPrazo, setComPrazo] = useState(true);
  const [vencidasSomente, setVencidasSomente] = useState(false);
  const [lgpdFacilTaks, setLgpdFacilTaks] = useState(false);
  const [hojeSomente, setHojeSomente] = useState(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [criarTarefaModalVisible, setCriarTarefaModalVisible] =
    useState<boolean>(false);

  const handleShowModalCriar = () => {
    setCriarTarefaModalVisible(!criarTarefaModalVisible);
  };
  const handleShowModal = () => {
    showModal ? setShowModal(false) : setShowModal(true);
  };
  React.useEffect(() => {
    if (moduloTask.tasks?.[0]) {
      setLoading(false);
    }
  }, [moduloTask.tasks]);

  // Colunas para a tabela
  const columns = [
    {
      title: "Título",
      dataIndex: "title",
      key: "title",
      render: (text: string, record: any) => {
        return (
          <Tooltip title={text}>
            <span
              onClick={() => console.log(record)}
              style={{
                fontSize: "12px",
                textDecorationLine:
                  record.status === "Concluída" ? "line-through" : "none",
              }}
            >
              {record.tipo === "tarefa-pauta" && <Tag color="blue">CPPD</Tag>}
              {record.tipo === "lgpdfacil" && (
                <Tag color="#7B5848">LGPD Fácil</Tag>
              )}
              {(record.tipo === "questionarios" ||
                (record.tipo_dependencia === "normal" && !record.tipo)) && (
                <Tag color="yellow">QUEST</Tag>
              )}
              {text}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: "Cliente",
      dataIndex: "cliente_id",
      key: "cliente_id",
      sorter: (a: any, b: any) => {
        const getClienteName = (clienteId: string, record: any) => {
          if (clienteId) {
            return (
              clientes?.find((c: any) => c._id === clienteId)?.razao_social ||
              "-"
            );
          } else if (record.assignment_id) {
            return (
              clientes?.find(
                (c: any) =>
                  c._id ===
                  usuarios?.find((u: any) => u._id === record.assignment_id)
                    ?.cliente_id
              )?.razao_social || "-"
            );
          } else if (record.owner_id) {
            return (
              clientes?.find(
                (c: any) =>
                  c._id ===
                  usuarios?.find((u: any) => u._id === record.owner_id)
                    ?.cliente_id
              )?.razao_social || "-"
            );
          } else {
            return "-";
          }
        };

        const nameA = getClienteName(a.cliente_id, a).toLowerCase();
        const nameB = getClienteName(b.cliente_id, b).toLowerCase();

        return nameA.localeCompare(nameB);
      },
      render: (clienteId: string, record: any) => {
        const getClienteName = () => {
          if (clienteId) {
            return clientes?.find((c: any) => c._id === clienteId)
              ?.razao_social;
          } else if (record.assignment_id) {
            return clientes?.find(
              (c: any) =>
                c._id ===
                usuarios?.find((u: any) => u._id === record.assignment_id)
                  ?.cliente_id
            )?.razao_social;
          } else if (record.owner_id) {
            return clientes?.find(
              (c: any) =>
                c._id ===
                usuarios?.find((u: any) => u._id === record.owner_id)
                  ?.cliente_id
            )?.razao_social;
          } else {
            return "-";
          }
        };

        return (
          <span style={{ fontSize: "12px" }}>
            {lgpdFacilTaks ? "LGPD Fácil" : getClienteName()}
          </span>
        );
      },
    },
    {
      title: "Atribuição",
      dataIndex: "assignment_id",
      key: "assignment_id",
      render: (assignmentId: string) => (
        <span style={{ fontSize: "12px" }}>
          {usuarios?.find((u: any) => u._id === assignmentId)?.name}
        </span>
      ),
    },
    {
      title: "Prazo",
      dataIndex: "prazo_conclusao",
      key: "prazo_conclusao",
      sorter: (a: any, b: any) => {
        const dateA = a.prazo_conclusao
          ? new Date(a.prazo_conclusao).getTime()
          : Infinity;
        const dateB = b.prazo_conclusao
          ? new Date(b.prazo_conclusao).getTime()
          : Infinity;
        return dateA - dateB;
      },
      render: (prazo: any) => {
        const today = moment().startOf("day");
        const prazoDate = moment(prazo).startOf("day");

        let color = "";
        if (prazoDate.isBefore(today)) {
          color = "red";
        } else if (prazoDate.isSame(today)) {
          color = "orange";
        } else {
          color = "green";
        }

        return (
          <span style={prazo ? { color, fontWeight: "bold" } : {}}>
            {prazo ? moment(new Date(prazo)).format("DD/MM/YYYY") : "-"}
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Em andamento", value: "Em andamento" },
        { text: "Concluída", value: "Concluída" },
        { text: "Pendente", value: "Pendente" },
      ],
      onFilter: (value: any, record: any) => record.status === value,
      render: (status: string) => (
        <Tag
          color={
            status === "Em andamento"
              ? "blue"
              : status === "Concluída"
              ? "green"
              : "grey"
          }
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Data de criação",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a: any, b: any) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (data: any) =>
        data ? moment(new Date(data)).format("DD/MM/YYYY") : "-",
    },
    {
      title: "Ações",
      key: "actions",
      render: (_: any, record: any) => (
        <Button
          type="link"
          icon={<EyeFilled />}
          onClick={() => {
            moduloTask.setSelectedTask(record);
            handleShowModal();
          }}
        >
          Ver tarefa
        </Button>
      ),
    },
  ];

  // Renderizar os blocos de tarefas
  const renderBlocks = () => {
    const statuses = ["Pendente", "Em andamento", "Concluída"];

    const tasks = currentCliente?._id
      ? moduloTask.tasksCliente
      : moduloTask.tasks;

    return (
      <div className="task-columns" style={{ marginLeft: "-2rem" }}>
        {statuses.map((status) => (
          <div>
            <h3 style={{ marginLeft: "2rem" }}>{status}</h3>
            <div key={status} className="task-column">
              {tasks
                .filter((t) => t.tipo !== "reuniao")
                // .filter((t) => {
                //   if (vencidasSomente) {
                //     return (
                //       moment(new Date(t.prazo_conclusao)).isBefore(
                //         moment(),
                //         "day"
                //       ) && t.status !== "Concluída"
                //     );
                //   } else {
                //     return true;
                //   }
                // })
                .filter((t) => {
                  if (hojeSomente) {
                    return moment(new Date(t.prazo_conclusao)).isSame(
                      moment(),
                      "day"
                    );
                  } else if (vencidasSomente) {
                    return (
                      moment(new Date(t.prazo_conclusao)).isBefore(
                        moment(),
                        "day"
                      ) && t.status !== "Concluída"
                    );
                  } else if (lgpdFacilTaks) {
                    return t.tipo === "lgpdfacil";
                  } else {
                    return true;
                  }
                })
                .filter(
                  (t) =>
                    t.tipo !== "reuniao" && t.tipo_dependencia !== "checklist"
                )
                .filter((t) => {
                  return comPrazo ? t.prazo_conclusao : true;
                })
                .filter((task) => task.status === status) // Filtro de status
                .sort((a, b) => {
                  const dateA = a.prazo_conclusao
                    ? new Date(a.prazo_conclusao).getTime()
                    : -Infinity;
                  const dateB = b.prazo_conclusao
                    ? new Date(b.prazo_conclusao).getTime()
                    : -Infinity;
                  return dateB - dateA; // Ordem decrescente para mostrar as mais atrasadas
                })
                .map((task) => (
                  <Card
                    extra={
                      task.prazo_conclusao &&
                      moment(new Date(task.prazo_conclusao)).isBefore(
                        moment(),
                        "day"
                      ) ? (
                        <Tag color="red">Atrasada</Tag>
                      ) : null // Usar null em vez de false
                    }
                    key={task.assignment_id}
                    size="small"
                    className="task_card"
                    bordered={false}
                    title={
                      <Tooltip title={task.title}>
                        <span
                          style={{
                            textDecorationLine:
                              task.status === "Concluída"
                                ? "line-through"
                                : "none",
                          }}
                        >
                          {task.title}
                        </span>
                      </Tooltip>
                    }
                    actions={[
                      <Button
                        type="link"
                        icon={<EyeFilled />}
                        size="small"
                        onClick={() => {
                          moduloTask.setSelectedTask(task);
                          handleShowModal();
                        }}
                      >
                        Ver tarefa
                      </Button>,
                    ]}
                  >
                    <p>
                      Prazo:{" "}
                      <b
                        style={{
                          color:
                            task.prazo_conclusao &&
                            moment(new Date(task.prazo_conclusao)).isBefore(
                              moment(),
                              "day"
                            )
                              ? "red"
                              : "inherit", // Verifica se o prazo está atrasado
                        }}
                      >
                        {moment(new Date(task.prazo_conclusao)).format(
                          "DD/MM/YYYY"
                        ) || "-"}
                      </b>
                    </p>

                    <p>
                      Status:{" "}
                      <Tag color={getStatusColor(task.status)}>
                        {task.status}
                      </Tag>
                    </p>
                  </Card>
                ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderTable = () => {
    const tasks = currentCliente?._id
      ? moduloTask.tasksCliente
      : moduloTask.tasks;

    let filteredTasks = tasks
      ?.filter((t) => {
        if (hojeSomente) {
          return moment(new Date(t.prazo_conclusao)).isSame(moment(), "day");
        } else if (vencidasSomente) {
          return (
            moment(new Date(t.prazo_conclusao)).isBefore(moment(), "day") &&
            t.status !== "Concluída"
          );
        } else if (lgpdFacilTaks) {
          return t.tipo === "lgpdfacil";
        } else {
          return true;
        }
      })
      .filter((t) => t.tipo !== "reuniao" && t.tipo_dependencia !== "checklist")
      .filter((t) => (comPrazo ? t.prazo_conclusao : true));

    return <Table size="small" dataSource={filteredTasks} columns={columns} />;
  };

  React.useEffect(() => {
    if (currentCliente?._id) {
      setLoading(true);
      moduloTask
        ?.fetchClienteTasks(currentCliente._id)
        .finally(() => setLoading(false));
    }
  }, [currentCliente]);

  // Definir cor do status
  const getStatusColor = (status: any) => {
    switch (status) {
      case "Em andamento":
        return "blue";
      case "Concluída":
        return "green";
      default:
        return "grey";
    }
  };

  return loading ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Spin />
      <p style={{ fontSize: 14, opacity: 0.5, marginTop: "0.5rem" }}>
        {currentCliente?._id
          ? `Carregando tarefas do cliente ${currentCliente?.razao_social}, isso pode levar algum tempo...`
          : "Carregando tarefas de todos os clientes, isso pode levar algum tempo..."}
      </p>
    </div>
  ) : (
    <div className="tasks-container">
      {!currentCliente?._id && (
        <>
          <Alert
            type="info"
            showIcon
            message="Atenção! Abaixo estão listadas apenas tarefas com prazos inferior ao dia seguinte e não concluídas. Para visualizar todas as tarefas, você deve selecionar um cliente."
          />
          <br />
        </>
      )}

      <Space style={{ marginBottom: 16 }}>
        <Button
          type="primary"
          style={{ backgroundColor: "green" }}
          icon={<ScheduleOutlined />}
          onClick={handleShowModalCriar}
        >
          Adicionar tarefa
        </Button>
        <Radio.Group
          size="small"
          value={viewMode}
          onChange={(e) => setViewMode(e.target.value)}
        >
          <Radio.Button value="blocks">Blocos</Radio.Button>
          <Radio.Button value="table">Tabela</Radio.Button>
        </Radio.Group>
        {currentCliente?._id && (
          <Radio.Group
            size="small"
            value={comPrazo}
            onChange={(e) => setComPrazo(e.target.value)}
          >
            <Radio.Button value={false}>Todas</Radio.Button>
            <Radio.Button value={true}>Com prazo</Radio.Button>
          </Radio.Group>
        )}
        <Switch
          size="small"
          checked={vencidasSomente}
          onChange={(checked: any) => setVencidasSomente(checked)}
          checkedChildren="Vencidas"
          unCheckedChildren="Vencidas"
        />
        <Switch
          size="small"
          checked={hojeSomente}
          onChange={(checked: any) => setHojeSomente(checked)}
          checkedChildren="Prazo hoje"
          unCheckedChildren="Prazo hoje"
        />
        <Switch
          size="small"
          checked={lgpdFacilTaks}
          onChange={(checked: any) => {
            setLgpdFacilTaks(checked);
            setHojeSomente(false);
            setVencidasSomente(false);
            setComPrazo(false);
          }}
          checkedChildren="Tarefas LGPD Fácil"
          unCheckedChildren="Tarefas LGPD Fácil"
        />

        {currentCliente?._id && (
          <Button
            size="small"
            type="primary"
            onClick={() => setCurrentCliente(null)}
            icon={<ClearOutlined />}
          >
            Limpar seleção de cliente
          </Button>
        )}
      </Space>
      <hr />

      {viewMode === "blocks" ? renderBlocks() : renderTable()}

      {/* Modal tarefa */}
      <CriarTarefa
        tipo={"tarefa-comum"}
        handleShowModal={handleShowModalCriar}
        showModal={criarTarefaModalVisible}
      />
      <Task setShowModal={setShowModal} showModal={showModal} />
    </div>
  );
};

export default TaskBlocks;
